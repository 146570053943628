/* platinum examination panel template */

/* left-bar */

#platinum .panel-left-fangle {
    background-image: url('../../images/platinum/platinum-left-fangle.png');
    background-repeat: no-repeat;
    background-position-y: 68%;
}

#platinum .panel-left-top-image {
    background-image: url('../../images/platinum/platinum-top-left.png');
}

#platinum .panel-left-mid-content {
    background-image: url('../../images/platinum/platinum-sidebar-left-back.png');
}

#platinum .panel-left-sidebar-image {    
    background-image: url('../../images/platinum/platinum-sidebar-left-top.png');
    background-repeat: no-repeat;
}

#platinum .panel-left-sidebar-bottom-image {
    background-image: url('../../images/platinum/platinum-sidebar-left-bottom.png');
    background-repeat: no-repeat;
}

#platinum .panel-left-sidebar-bottom-image {
    background-image: url('../../images/platinum/platinum-sidebar-left-bottom.png');
    background-repeat: no-repeat;
}

#platinum .panel-left-bottom-image {
    background-image: url('../../images/platinum/platinum-bottom-left-bottom.png');                    
    background-repeat: no-repeat;
}

/* body */

#platinum .panel-middle-header-image {
    background-image: url('../../images/platinum/platinum-topbar-header.png');
    background-repeat: no-repeat;
}

#platinum .panel-middle-top-shadow {
    background-image: url('../../images/platinum/platinum-topbar-shadow.png');
    background-repeat: no-repeat;
}

#platinum .panel-middle-body-content {
    background-image: url('../../images/platinum/platinum-middle-bottom-bartop.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

#platinum .panel-middle-footer-image {
    background-image: url('../../images/platinum/platinum-middle-bottom-barbottom.png');
    background-repeat: no-repeat;
}

/* right-bar */

#platinum .panel-right-fangle {
    background-image: url('../../images/platinum/platinum-right-fangle.png');
    background-repeat: no-repeat;
    background-position-y: 68%;
    background-position-x: 11px;
}

#platinum .panel-right-top-image {
    background-image: url('../../images/platinum/platinum-top-right.png');
}

#platinum .panel-right-mid-content {
    background-image: url('../../images/platinum/platinum-sidebar-right-back.png');
}

#platinum .panel-right-sidebar-image {    
    background-image: url('../../images/platinum/platinum-sidebar-right-top.png');
    background-repeat: no-repeat;
}

#platinum .panel-right-bottom-image {
    background-image: url('../../images/platinum/platinum-sidebar-right-bottom.png');
    background-repeat: no-repeat;
}

#platinum .panel-right-sidebar-bottom-image {
    background-image: url('../../images/platinum/platinum-sidebar-right-bottom.png');
    background-repeat: no-repeat;
}

#platinum .panel-right-bottom-image {
    background-image: url('../../images/platinum/platinum-bottom-right-bottom.png');                    
    background-repeat: no-repeat;
}