/* Search input box */
#search_box {
    padding-top: 4px;
}

#searchInput {
    font-size: 1.0em;
    border: 1px solid #777;
    background-color: #111;
    color: antiquewhite;
}

.logo-text {
    margin-top: 8px;
    margin-left: 8px;
}

.custom-control-label::before {
    background-color: #111 !important;
}

.custom-control-label::after {
    border: 1px solid #777;
    background-color: #555 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border: 1px solid antiquewhite;
    background-color: #111 !important;
}