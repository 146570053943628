/* gear list item css for the links on the left hand menu column. */

.gear-list {
    padding-top: 8px;
    width: 100%;
}

.gear-list-item-container {
    display: flex;
    font-size: 1em;
    color: rgb(255, 215, 80);
    padding: 5px;
}

.gear-list-item-icon {
    padding: 2px;
    width: 32px;
    flex-grow: 0 !important;
}

.gear-list-item {
    padding: 2px;
    display: flex;
    font-size: 1em;
    width: 100%;
    text-align: left;
    color: rgb(255, 215, 80);
}

.gear-list-item a {
    color: rgb(255, 215, 80);
}

.gear-list-item:hover {
    color: #2FD5E7;
}

.gear-list-item a:active {
    color: #7C6E39;
}

.gear-list-item-text {
    font-size: 1em;
    padding-right: 4px;
    margin-left: 7px;
}

.gear-list-item-details {
    font-size: 0.9em;
    color: #ffffe0;
}

.gear-list-item > div {
    flex-grow: 1;
}

.gear-list-item:nth-child(2n+2) {
    background: linear-gradient(
    rgba(0, 0, 0, 0.1), 
    rgba(0, 0, 0, 0.1)
    )
}

.gear-list-item:hover {
    background: linear-gradient(
    rgba(0, 0, 0, 0.4), 
    rgba(0, 0, 0, 0.4)
    )
}