/* examination panel base by RabidSquirrel */

/* top-level */

#examine {
    float: left;
}

.panel-container {
    color: #d6d8d8;
    width: 473px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.enbolden {
    color: white;
}

.color-00BFFF {
    color: #00bfff;
}

.color-6e5c8e {
    color: #6e5c8e;
}

.color-66FF99 {
    color: #66FF99;
}

.color-99fdff {
    color: #99fdff;
}

.color-eeef00 {
    color: #eeef00;
}

.color-ff0000 {
    color: #ff0000
}

.color-FFFFe0 {
    color: #ffffe0;
}

.color-bc4f4f {
    color: #bc4f4f;
}

.color-A020F0 {
    color: #a020f0;
}

.color-a970ff {
    color: #a970ff;
}

.color-ba3737 {
    color: #ba3737;
}

.color-D5D505 {
    color: #D5D505;
}

.color-D50505 {
    color: #D50505
}

.color-d6d8d8 {
    color: #d6d8d8;
}

.color-FFE28E {
    color: #FFE28E;
}

.color-FFFB3D {
    color: #FFFB3D;
}

/* left side-bar */

.panel-left-sidebar {
    width: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.panel-left-sidebar div {
    width: 50px;
}

.panel-left-top-content {
    background-image: url('../images/background-top-left.png');
    height: 37px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.panel-left-fangle {
    flex-grow: 10;
    z-index: 401;
}

.panel-left-top-image {
    height: 37px;
}

.panel-left-mid-content {
    height: 100%;
    position: relative;
    z-index: 1;
    flex-grow: 10;
    display: flex;
}

.panel-left-sidebar-image {
    min-height: 65px;
    height: 100%;
    flex-grow: 10;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.panel-left-sidebar-bottom-image {
    align-self: flex-end;
    min-height: 53px;
    z-index: 201;
    position: relative;
}

.panel-left-sidebar-bottom-image {
    align-self: flex-end;
    min-height: 53px;
    z-index: 201;
    position: relative;
}

.panel-left-bottom-content {
    align-self: flex-end;
    min-height: 43px;
    z-index: 1;
    background-image: url('../images/background-bottom-left.png');
    background-repeat: no-repeat;
}

.panel-left-bottom-image {
    height: 43px;
    z-index: 201;
    position: relative;
}

/* body */

.panel-middle {
    width: 375px;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
}

.panel-middle div {
    max-width: 375px;
}

.panel-middle-header {
    height: 37px;
    background-image: url('../images/background-top-middle.png');
    background-repeat: no-repeat;
    position: relative;
}

.panel-middle-header-image {
    height: 37px;
    position: relative;
}

.panel-middle-body-container {
    background-image: url('../images/background-mid.png');
    min-height: 76px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.panel-middle-body-content {
    min-height: 76px;
    position: relative;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    word-spacing: 1px;
}

.engolden {
    color: gold !important;
}

.panel-quest-difficulty {
    font-size: 1.2em;
    text-align: right;
    /*display: inline-block;
    width: 37%;*/
    /*background-color: purple; /* just for testing */
}

.Normal, .Epic, .Casual, .Epic_Casual {
    color: blue;
}

.Hard, .Epic_Hard {
    color: orange;
}

.Elite {
    color: red !important;
}

.Epic_Elite, .EliteFinished {
    color: red !important;
}

.Reaper, .Epic_Reaper {
    color: purple !important;
}

.panel-middle-body-content p {
    margin: 0;
    padding: 0;
}

.panel-main-spell {
    color: #ABA060;
    display: flex;
    flex-direction: row;
    line-height: 1.2em;
}

.panel-main-spell-right div {
    padding-bottom: 0.4px;
    margin: 0;
    font-size: 0.9789em;
}

.panel-main-spell-text {
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 4px;
    margin-right: 4px;
}

.panel-main-spell-text h1 {
    color: #ffffe0;
    font-weight: bold;
    font-size: 12px;
    line-height: 0;
}

.panel-main-spell-targets {
    display: flex;
    flex-direction: row;
}

.panel-main-spell-targets span:first-child:before {
    content: ' ';
    padding-left: 4px;
}

.panel-main-weapon-attack-mod span:not(:first-child)::before, .panel-main-weapon-damage-mod span:not(:first-child)::before,
.panel-main-spell-targets span:not(:first-child)::before {
    content: ', ';
}

.panel-main-spell-targets {
    display: flex;
    flex-direction: row;
}

.panel-main-spell-description div::after {
    content: ' ';
    white-space: pre-wrap;
}

.panel-main-dropped-on-death, .panel-main-dropped-on-leaving-adventure {
    color: white;
}

.panel-main-weapon-attack-mod, .panel-main-weapon-damage-mod {
    display: flex;
    flex-direction: row;
}

.panel-main-weapon-attack-mod div, .panel-main-weapon-damage-mod div {
    margin-left: 8px;
}

.panel-main-row-one {
    display: flex;
    flex-direction: row;
}

.panel-main-row-two, .panel-main-row-three {
    display: flex;
    flex-direction: column;
}

.panel-main-row-three {
    padding-right: 10px;
}

.panel-main-icon {
    margin-left: 5px;
}

.panel-item {
    font-size: 13px;
    color: #C9C9C9;
    width: 325px;
    text-align: center;
}

.panel-item-name {
    margin-top: -2px;
    text-align: center;
}

.panel-main-accept-sentience {
    color: #FFCA44;
}

.panel-main-artifact {
    color: #00B8E6;
}

.panel-main-spacer-after-name {
    width: 375px;
    height: 11px;
}

.panel-main-equips-to {
    width: 375px;
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 1px;
}

.panel-main-equips-wrap-right {
    display: flex;
    flex-direction: row;
}

.panel-main-equips-wrap-right p {
    margin-right: 8px;
}

.panel-main-equips-wrap-right div:not(:nth-child(2))::before {
    content: ','
}

.panel-main-proficiency, .panel-main-binding, .panel-main-umd-diff,
.panel-main-exclusive {
    color: white;
}

.panel-main-umd-list {
    display: flex;
    flex-direction: row;
}

.panel-main-umd-list div:first-child {
    margin-left: 8px;
}


.panel-main-clicky {
    margin-top: 3px;
}

.panel-main-umd-list div:not(:first-child)::before {
    content: ', '
}

.panel-main-ingredient-type, .panel-main-armor-adamantine, .panel-main-armor-mithral,
.panel-main-set-bonus-one, .panel-main-set-bonus-two,
.panel-main-pet-races-required,
.panel-main-races-excluded, .panel-main-races-required,
.panel-main-umd-list,
.panel-main-absolute-min-level, .panel-main-dropped-on-death, .panel-main-dropped-on-leaving-adventure,
.panel-main-min-level, .panel-main-binding, .panel-main-proficiency,
.panel-main-umd-diff, .panel-main-exclusive, .panel-main-feat-required {
    padding-bottom: 3px;
}

.panel-main-durability {
    margin-bottom: 3px;
}

.panel-main-armor-docent span {
    color: white;
}

.panel-main-armor-adamantine {
    padding-top: 4px;
}

.panel-main-armor-adamantine, .panel-main-armor-mithral {
    color: lightgreen;
}

.panel-main-armor-mithral::before,
.panel-main-armor-adamantine::before {
    content: ' ';
    background-image: url('../images/effect-arrow.png');
    color: gold;
    width: 4px;
    height: 8px;
    display: inline-block;
    margin-right: 4px;
    margin-left: 2px;
}

.panel-main-recharges {
    background-image: url('../images/charge-background.png');
    height: 16px;
    text-align: center;
    color: #ADFF9F;
    text-shadow: 0px 0px 1px black, 0 0 2px black, 0 0 5px black;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 10px;
}

.panel-main-base-damage-rating-value {
    padding-left: 4px;
    font-size: 14px;
    font-weight: bolder;
}

.panel-main-base-damage-rating {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
    padding-top: 2px;
}

.type-and-diff-wrapper {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-flex-spacer {
    font-size: 1.2em;
    text-align: left;
    /*background-color: red; /* just for testing */
    visibility: hidden;
}

.panel-main-shield-type,
.panel-main-armor-type,
.panel-main-weapon-type {
    /*display: inline-block;
    text-align: right;
    width: 63%;*/
    /*background-color: green; /* just for testing */
    flex: 1;
}

.panel-main-shield > div,
.panel-main-armor > div,
.panel-main-weapon > div {
    padding-bottom: 3px;
    line-height: 1.24em;
}

.panel-main-shield > div > span:first-child,
.panel-main-armor > div > span:first-child,
.panel-main-weapon > div > span:first-child {
    color: white;
    padding-right: 4px;
}

.panel-main-weapon-enhancment {
    padding-left: 4px;
    padding-right: 4px;
}

.panel-main-weapon-damage-spacer {
    padding-left: 4px;
}

.panel-main-weapon-damage span:nth-child(3) {
    color: white;
}

.panel-main-weapon-damage-type:not(:last-child)::after {
    content: ',';
    padding-right: 4px;
}

.panel-main-effect-list {
    color: #ABA060;
    margin-bottom: 4px;
}

.panel-main-spell-failure,
.panel-main-armor-penalty, .panel-main-shield-penalty,
.panel-main-shield-penalty, .panel-main-attack-penalty {
    color: yellow;
}

.panel-main-shield-bonus, .panel-main-shield-damage-reduction {
    color: #66FF99;
}

.panel-main-effect-list-name::before, .panel-sub-effect::before {
    content: ' ';
    background-image: url('../images/effect-arrow.png');
    color: gold;
    width: 4px;
    height: 8px;
    display: inline-block;
    margin-right: 6px;
    margin-left: 2px;
}

.panel-main-effect {
    line-height: 1.2em;
}

.panel-main-effect-list-name, .panel-sub-effect {
    font-size: 12px;
    color: white;
}

.panel-sub-effect {
    padding-left: 13px;
}

.panel-sub-effect-description {
    color: #ABA060;
}

.panel-main-durability {
    background-image: url('../images/item-strength-background.png');
    max-height: 27px;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
}

.panel-main-durability::before {
    content: ' ';
    background-image: url('../images/item-strength-background-left-overlay.png');
    width: 1px;
    height: 25px;
}

.panel-main-durability::after {
    content: ' ';
    background-image: url('../images/item-strength-background-right-overlay.png');
    width: 1px;
    height: 25px;
}

.panel-main-durability-text {
    width: 373px;
    height: 25px;
    padding-top: 1px;
    text-align: center;
    font-size: 9px;
    color: #58D1FF;
    text-shadow: 1px 1px #000000;
    border-top: 1px solid lightslategray;
    border-bottom: 1px solid lightslategray;
    line-height: 1;
}

.panel-main-set-bonus-one, .panel-main-set-bonus-two {
    color: white;
}

.panel-main-set-bonus-one-name, .panel-main-set-bonus-two-name {
    color: #79f2e6;
}

.panel-main-description {
    font-size: 13px;    
    line-height: 1.2em;
}

/* data break */

.panel-middle-top-shadow {
    position: relative;
    display: flex;
}

.panel-middle-footer-data {
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.panel-middle-coinage-value {
    width: 263px;
    margin-left: 14px;
    display: flex;
    flex-direction: row;
}

.panel-middle-coinage-value div {
    margin-left: 4px;
    padding-right: 14px;
}

.panel-middle-coinage-container {
    display: flex;
    flex-direction: row;
    margin-top: -1px;
}

.panel-middle-platinum-coinage,
.panel-middle-gold-coinage,
.panel-middle-silver-coinage,
.panel-middle-copper-coinage {
    margin-top: -1px;
}


.panel-middle-platinum-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/platinum-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-gold-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/gold-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-silver-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/silver-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-copper-coinage::after {
    content: ' ';
    padding-right: 18px;
    background-image: url('../images/copper-coinage.png');
    background-repeat: no-repeat;
    background-position-x: right;
    width: 18px;
    height: 21px;
    position: absolute;
    z-index: 402;
}

.panel-middle-weight {
    width: 112px;
    text-align: center;
    margin-top: 1px;
}

.panel-middle-footer {
    background-image: url('../images/background-bottom-middle.png');
    height: 43px;
    align-content: flex-end;
    background-repeat: no-repeat;
}

.panel-middle-footer-image {
    height: 43px;
}

.extra-info {
    border-radius: 20px;
    border: 2px solid rgb(255, 215, 80);
    background-image: url('../images/background-mid.png');
    margin-left: 40px;
    margin-right: 40px;
    padding: 8px;
    width: 473px;
    display: flex;
    flex-direction: column;
    font-size: 1em;
    min-height: 2px;
}

/* right side-bar */

.panel-right-sidebar {
    width: 48px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.panel-right-sidebar div {
    width: 48px;
}

.panel-right-top-content {
    background-image: url('../images/background-top-right.png');
    height: 37px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.panel-right-top-image {
    height: 37px;
}

.panel-right-mid-content {
    height: 100%;
    position: relative;
    z-index: 1;
    flex-grow: 10;
    display: flex;
}

.panel-right-sidebar-image {
    flex-grow: 10;
    overflow: hidden;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.panel-right-fangle {
    flex-grow: 10;
    z-index: 401;
}

.panel-right-bottom-image {
    align-self: flex-end;
    z-index: 201;
    position: relative;
}

.panel-right-sidebar-bottom-image {
    align-self: flex-end;
    min-height: 53px;
    z-index: 201;
    position: relative;
}

.panel-right-bottom-content {
    background-image: url('../images/background-bottom-right.png');
    background-repeat: no-repeat;
    align-self: flex-end;
    min-height: 43px;
    z-index: 1;
}

.panel-right-bottom-image {
    height: 43px;
    z-index: 201;
    position: relative;
}


/* this button acts like a link: */
.examination-button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: rgb(255, 215, 80);
    text-decoration: none;
    cursor: pointer;
}

.examination-button:hover {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #2FD5E7;
    text-decoration: underline;
    cursor: pointer;
}

.examination-button:active {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #7C6E39;
    text-decoration: underline;
    cursor: pointer;
}

.panel-extra-adventure-pack {
    padding-left: 10px;
}

ul {
    padding-left: 10px;
}

li {
    padding-left: 10px;
    list-style-position: inside;
}