.soundboard {
    color: antiquewhite;
}

.sound-type {
    color: gold;
}

.sound-name {
    color: antiquewhite;
    padding-left: 20px;
}