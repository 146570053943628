* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important; 
  font-size: 0.95rem;
  background: #0E1931;  
  background: linear-gradient(to right, #0E1931, #125398);
  background: -moz-linear-gradient(to right, #0E1931, #125398);
  background: -ms-linear-gradient(to right, #0E1931, #125398);
  background: -webkit-linear-gradient(to right, #0E1931, #125398);
  background: -webkit-linear-gradient(to right, #0E1931, #125398);
  background: -o-linear-gradient(to right, #0E1931, #125398);
  background:  linear-gradient(to right, #0E1931, #125398);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;
}

small {
  color: antiquewhite;
}

a {
  color: rgb(255, 215, 80) !important; 
  text-decoration: none;
}

a:hover {
  color: #2FD5E7 !important; 
  text-decoration: underline;
}

a:active {
  color: #7C6E39 !important; 
  text-decoration: underline;
}

.App {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;  
  background: linear-gradient(to right, #0E1931, #125398);
  background: -moz-linear-gradient(to right, #0E1931, #125398);
  background: -ms-linear-gradient(to right, #0E1931, #125398);
  background: -webkit-linear-gradient(to right, #0E1931, #125398);
  background: -webkit-linear-gradient(to right, #0E1931, #125398);
  background: -o-linear-gradient(to right, #0E1931, #125398);
  background: linear-gradient(to right, #0E1931, #125398);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#full_text_toggle {
  padding-top: .5rem;
}

#searching {
  color: antiquewhite !important;
  margin: 0 0.5rem 0 .75rem;
  max-width: 320px;
}

@media all and (min-width: 992px) {
  #display {
    margin: 0 .25rem 0 0;
  }
}

@media all and (max-width: 991px) {
  #display {
    margin: 0 0 0 -.5rem;
  }
  #searching {
    max-width: 97%;
    margin-left: 10px;
  }
}

@media all and (max-width: 768px) {
  .row {
    padding-right: .55rem;
  }
  #results {
    margin-right: .55rem;
  }
  #searching {
    max-width: initial;
  }
  #display {
    max-width: initial;  
  }
  .about-us {
    padding-top: 20px;
    min-width: 330px;
  }
  .adventure-packs {
    padding-top: 20px;
    min-width: 330px;
  }
}

@media all and (max-width: 493px) {
  #display {
    margin-left: -30px;
    margin-right: -30px;
    -webkit-transform:scale(0.88);
    -moz-transform:scale(0.88);
    -ms-transform:scale(0.88);
    transform:scale(0.88);
  }
  .about-us {
    min-width: 330px;
  }
  .adventure-packs {
    min-width: 330px;
  }
}

@media all and (max-width: 430px) {
  #display {
    margin-left: -60px;
    margin-right: -60px;
    -webkit-transform:scale(0.78);
    -moz-transform:scale(0.78);
    -ms-transform:scale(0.78);
    transform:scale(0.78);
  }
}

@media all and (max-width: 360px) {
  #display {
    margin-left: -100px;
    margin-right: -100px;
    -webkit-transform:scale(0.62);
    -moz-transform:scale(0.62);
    -ms-transform:scale(0.62);
    transform:scale(0.62);
  }
}

.no-search-results {    
  color: #2FD5E7;
  margin: 12px;
  text-align: center;    
  border-top: 2px solid #137AC4;
  border-left: 2px solid #137AC4;
  border-bottom: 2px solid #125398;
  border-right: 2px solid #125398;
  padding: 8px;
  background-color: black;
}

#results {
  color: antiquewhite;
  margin-left: -.75rem;
  padding-left: 15px;
  min-width: 200px;
}

#pagination > div {
  text-align: center;
  color: antiquewhite;
  font-weight: bolder;
  font-size: 1.4em;
  padding-top: 4px;
  padding-bottom: 4px;
}

.error-message {  
  color: red;
  font-weight: bolder;
  width: 360px;
  margin: 20px;
  text-align: center;    
  border-top: 2px solid #137AC4;
  border-left: 2px solid #137AC4;
  border-bottom: 2px solid #125398;
  border-right: 2px solid #125398;
  padding: 8px;
  background-color: black;
}

.btn {
  width: 100%;
}


body {
  -ms-overflow-style: none;
}

.error-message {  
  color: red;
  font-weight: bolder;
  width: 360px;
  margin: 20px;
  text-align: center;    
  border-radius: 20px;
  border: 2px solid rgb(255, 215, 80);
  padding: 8px;
  background-color: black;
}

.error-background {
  padding: 0;
  margin: auto 0;
  width: 100%;
  min-height: 768px;
  overflow: auto;
  background-repeat: no-repeat;
  background-image: url('../images/error-background.jpg') !important;
}

.gear-no-item-selected {
  width: 360px;
  margin: 20px;
  text-align: center;    
  border-radius: 20px;
  border: 2px solid rgb(255, 215, 80);
  background-image: url(../images/div-background.png);
  background-color: black;
}

.about-us {
  margin: 20px;
  margin-top: 100px;
  text-align: center;
  border-radius: 20px;
  border: 2px solid rgb(255, 215, 80);
  background-image: url(../images/div-background.png);
  padding: 8px;
  background-color: black;
  color: #fff;
  width: 100%;
  font-size: 0.85em;
}

.menu {
  display: flex;
  justify-content: center;
}

.menu li {
  background-color: #125398;
}

.adventure-packs, .quests, .starter-gear {
  margin: 20px;
  text-align: center;    
  border-radius: 20px;
  border: 2px solid rgb(255, 215, 80);
  background-image: url(../images/div-background.png);
  padding: 8px;
  background-color: black;
  color: #fff;
}

.gear-no-item-selected {
  width: 360px;
  margin: 20px;
  text-align: center;    
  border-top: 2px solid #137AC4;
  border-left: 2px solid #137AC4;
  border-bottom: 2px solid #0E1931;
  border-right: 2px solid #125398;
  background-color: black;
}

.paginationButton {
  padding: 5px 0px;
}

.about-us-starter-gear {
  display: inline-flex;
}

.search-results {
  padding-left: 15px;
  width: 460px;
}