/* */
.GearFilters {
    width: 100%;
}

#range_slider {
    padding: 0 1rem;
}

.ml-slider-container {
    width: 93%;
    margin: 0 auto;
}

.rc-slider-mark-text-active {
    color: antiquewhite !important;
}

.rc-slider-handle {
    background-color: #111 !important;
}

.rc-slider-rail {
    border-color: #0B4763 !important;
    background-color: #000 !important;
}

.rc-slider-dot, .rc-slider-track, .rc-slider-dot-active {
    border-color: #0B4763 !important;
    background-color: #000 !important;
}

.rc-slider-track, .rc-slider-dot-active {
    border-color: #0B4763 !important;
    background-color: #0B4763 !important;
}

.gf-select > div {
    border: 1px solid #777;
    background-color: #111;
    color: #2FD5E7;
}

.gf-select-items__option {
    color: red;
}

.gf-select-items__option--is-focused {
    border: 1px dashed #777;
    background-color: #000 !important;
    color: #2FD5E7 !important;
    margin: -1px;
}

.gf-select-items__option--is-selected {
    border: 1px solid #777;
    background-color: #111 !important;
    font-weight: bold !important;
    color: #2FD5E7 !important;
}

.gf-select-items__menu--is-focused {
    border: 1px solid #777 !important;
    background-color: #111 !important;
    font-weight: bold !important;
}

.gf-select-items__placeholder {
    color: #777 !important;
}

.gf-select-items__input,
.gf-select-items__single-value {
    color: antiquewhite !important;
}

/*
Ague Stuff below
 */

.gf-select > div {
    margin-top: -.001rem;
    line-height: 1.3em;
    color: antiquewhite;
}

.panel-container {
    margin-right: auto;
    margin-left: auto;
}
