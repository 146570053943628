.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 20000;
    display: block;
}

/* Apparently z-index is broken for Examine.css, this is workaround: */
.ReactModalPortal {
    z-index: 20000;
}

.ReactModal__Overlay--after-open {
    display: inline !important;
}

.modal-main {
    color: #fff;
    position:fixed;
    width: 60%;
    max-width: 600px;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);    
    z-index: 20000;
    padding: 20px;
    border-radius: 20px;
    border: 2px solid rgb(255, 215, 80);
    background-image: url(../images/div-background.png);
}

.modal-main textarea {
    width: 100%;
    height: 90px;
    max-width: 520px;
    max-height: 350px;
}

.modal-main-button {
    margin: 5px;
}

/* this button acts like a link: */
.modal-main-button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: rgb(255, 215, 80);
    text-decoration: none;
    cursor: pointer;
}

.modal-main-button:hover {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #2FD5E7;
    text-decoration: underline;
    cursor: pointer;
}

.modal-main-button:active {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #7C6E39;
    text-decoration: underline;
    cursor: pointer;
}

.top-close-button {
    position: absolute;
    right: 0;
    top: 0;
}
  
.reportIssueInput {
    background-color: #fff;
}

.userName {    
    width: auto;
    min-width: 10px;
}