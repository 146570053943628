/* gold examination panel template */

/* left-bar */

#gold .panel-left-top-image {
    background-image: url('../../images/gold/gold-top-left.png');
}

#gold .panel-left-mid-content {
    background-image: url('../../images/gold/gold-sidebar-left-back.png');
}

#gold .panel-left-sidebar-image {    
    background-image: url('../../images/gold/gold-sidebar-left-top.png');
    background-repeat: no-repeat;
}

#gold .panel-left-sidebar-bottom-image {
    background-image: url('../../images/gold/gold-sidebar-left-bottom.png');
    background-repeat: no-repeat;
}

#gold .panel-left-sidebar-bottom-image {
    background-image: url('../../images/gold/gold-sidebar-left-bottom.png');
    background-repeat: no-repeat;
}

#gold .panel-left-bottom-image {
    background-image: url('../../images/gold/gold-bottom-left-bottom.png');                    
    background-repeat: no-repeat;
}

/* body */

#gold .panel-middle-header-image {
    background-image: url('../../images/gold/gold-topbar-header.png');
    background-repeat: no-repeat;
}

#gold .panel-middle-top-shadow {
    background-image: url('../../images/gold/gold-topbar-shadow.png');
    background-repeat: no-repeat;
}

#gold .panel-middle-body-content {
    background-image: url('../../images/gold/gold-middle-bottom-bartop.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

#gold .panel-middle-footer-image {
    background-image: url('../../images/gold/gold-middle-bottom-barbottom.png');
    background-repeat: no-repeat;
}

/* right-bar */

#gold .panel-right-top-image {
    background-image: url('../../images/gold/gold-top-right.png');
}

#gold .panel-right-mid-content {
    background-image: url('../../images/gold/gold-sidebar-right-back.png');
}

#gold .panel-right-sidebar-image {    
    background-image: url('../../images/gold/gold-sidebar-right-top.png');
    background-repeat: no-repeat;
}

#gold .panel-right-bottom-image {
    background-image: url('../../images/gold/gold-sidebar-right-bottom.png');
    background-repeat: no-repeat;
}

#gold .panel-right-sidebar-bottom-image {
    background-image: url('../../images/gold/gold-sidebar-right-bottom.png');
    background-repeat: no-repeat;
}

#gold .panel-right-bottom-image {
    background-image: url('../../images/gold/gold-bottom-right-bottom.png');                    
    background-repeat: no-repeat;
}