/* copper examination panel template */

/* left-bar */

#copper .panel-left-top-image {
    background-image: url('../../images/copper/copper-top-left.png');
}

#copper .panel-left-mid-content {
    background-image: url('../../images/copper/copper-sidebar-left-back.png');
}

#copper .panel-left-sidebar-image {    
    background-image: url('../../images/copper/copper-sidebar-left-top.png');
    background-repeat: no-repeat;
}

#copper .panel-left-sidebar-bottom-image {
    background-image: url('../../images/copper/copper-sidebar-left-bottom.png');
    background-repeat: no-repeat;
}

#copper .panel-left-sidebar-bottom-image {
    background-image: url('../../images/copper/copper-sidebar-left-bottom.png');
    background-repeat: no-repeat;
}

#copper .panel-left-bottom-image {
    background-image: url('../../images/copper/copper-bottom-left-bottom.png');                    
    background-repeat: no-repeat;
}

/* body */

#copper .panel-middle-header-image {
    background-image: url('../../images/copper/copper-topbar-header.png');
    background-repeat: no-repeat;
}

#copper .panel-middle-top-shadow {
    background-image: url('../../images/copper/copper-topbar-shadow.png');
    background-repeat: no-repeat;
}

#copper .panel-middle-body-content {
    background-image: url('../../images/copper/copper-middle-bottom-bartop.png');
    background-repeat: no-repeat;
    background-position: bottom;
}

#copper .panel-middle-footer-image {
    background-image: url('../../images/copper/copper-middle-bottom-barbottom.png');
    background-repeat: no-repeat;
}

/* right-bar */

#copper .panel-right-top-image {
    background-image: url('../../images/copper/copper-top-right.png');
}

#copper .panel-right-mid-content {
    background-image: url('../../images/copper/copper-sidebar-right-back.png');
}

#copper .panel-right-sidebar-image {    
    background-image: url('../../images/copper/copper-sidebar-right-top.png');
    background-repeat: no-repeat;
}

#copper .panel-right-bottom-image {
    background-image: url('../../images/copper/copper-sidebar-right-bottom.png');
    background-repeat: no-repeat;
}

#copper .panel-right-sidebar-bottom-image {
    background-image: url('../../images/copper/copper-sidebar-right-bottom.png');
    background-repeat: no-repeat;
}

#copper .panel-right-bottom-image {
    background-image: url('../../images/copper/copper-bottom-right-bottom.png');                    
    background-repeat: no-repeat;
}